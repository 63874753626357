<template>
  <v-content>
    <v-container>
      <div class="mt-4" v-for="(item, index) in SO" :key="index">
        <SaleOrder
          :order="item.sales_order"
          :URL="item.receipt_url"
          v-if="typeof item.sales_order !== 'string'"
          isHeader
          status="completed"
        ></SaleOrder>
      </div>
    </v-container>
  </v-content>
</template>

<script>
import SaleOrder from '../components/SaleOrder'
import { mapState } from 'vuex'
export default {
  name: 'OrderCompleted',
  components: {
    SaleOrder,
  },
  data: () => {
    return {
      allSelected: true,
      order: null,
    }
  },
  computed: {
    ...mapState(['SO']),
  },
  methods: {
    // async getOrder() {
    //   const self = this
    //   try {
    //     const res = await self.$axios({
    //       methods: 'GET',
    //       url: '/api/salesorder/orderview',
    //       params: {
    //         social_id: self.$store.state.tokenId,
    //         salesorder_id: self.$route.query.id,
    //       },
    //     })
    //     res.data[0].map((v) => {
    //       return (self.order = v)
    //     })
    //   } catch (e) {
    //     self.$loadingModal('error')
    //   }
    // },
  },
  mounted() {
    //   this.getOrder()
    // console.log(this.SO)
  },
}
</script>

<style lang="scss" scoped></style>
